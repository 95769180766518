@import 'src/styles/shared';

.side-panel {
  z-index: 800; // above Main (100) & Annotations (500)
  position: absolute;

  pointer-events: none; // TODO UNCOMMENT IN PROD

  color: #020202;

  p {
    margin-block-start: 0;
  }

  &.fullpage {
    // ##########################################################
    background-color: white;

    top: px-to-rem(366px);
    bottom: px-to-rem(40px);

    // right: px-to-rem(360px);
    // left: px-to-rem(360px);
    // width: auto;

    // variable width: fix small width title/navigatin overlapping
    width: px-to-rem(1200px);
    max-width: 95%;
    height: auto;

    border-radius: px-to-rem(24px);

    font-size: px-to-rem(28px);

    .side-panel-content {
      height: 100%;

      padding: px-to-rem(40px) px-to-rem(40px) px-to-rem(40px) px-to-rem(40px);

      &>* {
        padding-inline-start: px-to-rem(40px);
      }

      ul {
        padding-inline-start: px-to-rem(90px);
      }

      li::before {
        display: inline-block;
        content: "";
        border-top: px-to-rem(2px) dashed $orange;
        width: px-to-rem(115px);
        margin-left: px-to-rem(-130px); // minus container margin
        margin-right: px-to-rem(15px);
        vertical-align: middle;
      }

      .side-panel-content-inner {
        pointer-events: auto;
        max-height: 100%;
        overflow-y: auto;
      }
    }

    .white-background {
      z-index: -1; // quickfix to place below content
      pointer-events: none;
      background-color: rgba(255, 255, 255, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .vertical-line {
      //z-index: 900; // display below buttons
      width: px-to-rem(8px); // add margin to width
      margin-left: px-to-rem(-4px);
      // position: fixed;
      // left: px-to-rem(400px);
      // top: px-to-rem(340px); // title top 260px + title height 80px
      position: absolute;
      left: px-to-rem(40px);
      top: px-to-rem(-26px);
      bottom: 0;
      background-color: $orange;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: thin;
      scrollbar-color: #ffffff #2f2f2f;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: #2f2f2f;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #2f2f2f;
    }
  }

  &.right {
    // ##########################################################
    background-color: rgba(229, 229, 229, .5);
    backdrop-filter: blur(30px) brightness(1.15);

    top: 0;
    right: 0;

    width: $side-panel-width;
    height: 100%;

    padding-top: px-to-rem(140px); // 170-30
    font-size: px-to-rem(22px);
    line-height: px-to-rem(34px);

    .side-panel-content {
      pointer-events: auto; // allow scrolling
      // height: 100%; //px-to-rem(450px);
      right: 32px;
      position: absolute;
      top: 10rem;
      bottom: 10rem;
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      justify-content: center;


      .side-panel-content-inner {
        pointer-events: auto;

        max-height: 160%;
        overflow-y: auto;

        &.limit-carrousel {
          //background-color: rgba(127, 255, 255, 0.5) !important; // TODO DEBUG
          max-height: 100%;
        }

        // margin: px-to-rem(40px) px-to-rem(32px) 0 0;
        margin: 0;
        // padding: 0 px-to-rem(40px) 10px px-to-rem(33px);
        padding: 0 px-to-rem(40px) 10px px-to-rem(60px);
      }

      p:first-of-type {
        margin-top: 0;
      }

      ul {
        padding-inline-start: px-to-rem(27px);
      }

      li::before {
        display: inline-block;
        content: "";
        border-bottom: px-to-rem(2px) dashed $orange;
        border-left: px-to-rem(2px) dashed $orange;
        width: px-to-rem(45px);
        height: px-to-rem(13px);
        margin-left: px-to-rem(-60px); // minus container margin
        margin-right: px-to-rem(15px);
        margin-top: px-to-rem(-13px);
        vertical-align: middle;
      }


      img {
        background-color: red; // TODO REMOVE DEBUG
        max-width: 75%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }

      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      * {
        scrollbar-width: thin;
        scrollbar-color: #ffffff #2f2f2f;
      }

      /* Chrome, Edge, and Safari */
      *::-webkit-scrollbar {
        width: 8px;
      }

      *::-webkit-scrollbar-track {
        background: #2f2f2f;
      }

      *::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #2f2f2f;
      }

    .vertical-line {
      //z-index: 900; // display below buttons
      position: fixed;
      width: px-to-rem(8px); // add margin to width
      margin-left: px-to-rem(-4px);
      top: px-to-rem(140px); // title top 60px + title height 80px
      bottom: 0;
      background-color: $orange;
    }
  }

  .side-panel-content {

    // ##########################################################
    //padding: px-to-rem(40px) px-to-rem(40px) px-to-rem(40px) px-to-rem(80px); // moved to .right / .fullscreen
    ul {
      //background-color: rgba(0,255,255,0.25);
      //padding-left: px-to-rem(60px);
      margin-block-start: 1rem;
      margin-block-end: 1rem;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }

    ul,
    li {
      //margin: 0;
      //padding: 0;
      list-style-type: none;
    }

    strong {
      color: $blue-dark;
    }
  }
}