@import 'src/styles/shared';

.fixedImageClosableAnnotationsContainer {
  background-color: rgba(255, 0, 0, 0.8);
  pointer-events: none; // TODO uncomment for prod
  //pointer-events: auto; // TODO debug

  position: fixed;
  left: calc((100% - #{$side-panel-width}) / 2);
  top: 50%;
  transform: translate(-50%, -50%);

  background-color: white;
  border-radius: px-to-rem(715px/2);

  .annotation.imageClosable {
    width: px-to-rem(715px);
    height: px-to-rem(715px);

    &.blurred-background {
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(2px); // TODO keep this?
    }

    .image {
      width: 100%;
      height: 100%;

      border-radius: px-to-rem(715px/2);
      overflow: hidden;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      img {
        object-fit: contain;
        width: 75%;
        height: 75%;
      }
    }

    .annotation-close {
      pointer-events: auto;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .fixedImageClosableAnnotationsContainer {
    .annotation.imageClosable {
      .annotation-close {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}