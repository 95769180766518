@import 'src/styles/shared';

.checkbox-container {
  cursor: pointer;
  pointer-events: auto;
  font-size: px-to-rem(22px);
  display: flex;
  align-items: center;

  &.secondary {
    font-size: px-to-rem(16px);
    margin-left: 1em;
  }

  .checkbox {
    border: solid 2px $blue-dark;
    border-radius: 3px;
    background-color: white;
    height: px-to-rem(18px);
    width: px-to-rem(18px);
    display: inline-block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  span {
    margin-left: 0.5em;
  }
}