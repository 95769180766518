@import 'src/styles/shared';

$circle-width: 40px;
$circle-radius: $circle-width / 2;
$dark-color: #182793;
$light-color: #748dbe;

.button-fixed-container {
  pointer-events: none;
  user-select: none;

  position: fixed;
  //z-index: 10000;

  .button-fixed.blueHover {
    pointer-events: auto;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(2px); // TODO keep this?

    min-width: unset !important;
    border-radius: 100rem !important; // round
    border: none !important;
    //box-shadow: none !important;
    box-shadow: 0.2rem 0.2rem 0.5rem 0 rgba(black, 0.2) !important;

    //transform-origin: center center;
    //transform: translate(-50%, -50%);

    //transition: all 0.3s;

    &.visible {
      opacity: 1;
    }

    .title {
      color: $dark-color;
      //width: 50%;
      padding: 0.75rem 1.5rem;
      display: inline-block;
      white-space: nowrap;

      font-size: 1.15rem;
      font-weight: 700;

      &.tablet {
        font-size: 1rem;
        padding: .75rem;
      }

      &.mobile {
        font-size: 0.85rem;
        padding: .5rem;
      }
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .button-fixed-container {
    .button-fixed:hover {
      cursor: pointer;
    }
  }
}