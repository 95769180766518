@import 'src/styles/shared';

.cumulation-side-panel {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  padding-top: 2em;
  gap: 1em;

  .checkboxes {
    background: white;
    color: $blue-dark;
    display: flex;
    max-width: 80%;
    width: 80%;
    padding: px-to-rem(20px);
    text-transform: uppercase;
    font-weight: bold;
    gap: 5em;
    border-radius: px-to-rem(24px);
  }

  .graph {
    max-width: 80%;
    width: 80%;

    img {
      //height: fit-content;
      object-fit: contain;
      max-width: 100%;
    }
  }
}
